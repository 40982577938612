<script setup lang="ts">
import debounce from 'lodash-es/debounce'
import { ref, watch, computed } from 'vue'
import ui from '/~/core/ui'
import { useGiftcards } from '/~/extensions/giftcards/composables'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseInput from '/~/components/base/input/base-input.vue'
import BaseSwitch from '/~/components/base/switch/base-switch.vue'
import BaseTab from '/~/components/base/tabs/base-tab.vue'
import BaseTabs from '/~/components/base/tabs/base-tabs.vue'
import EwCategories from '/~/components/ewallet/controls/ew-categories.vue'
import EwFilters from '/~/components/ewallet/controls/ew-filters.vue'
import EwSorting from '/~/components/ewallet/controls/ew-sorting.vue'

const {
  isPhysicalEnabled,
  cardTypes,
  categories,
  sortValues,
  searchQuery,
  selectedCategory,
  selectedSorting,
  selectedCardType,
  resetFilters,
  isFiltersSelected,
  searchGroup,
} = useGiftcards()

const localSearchQuery = ref(searchQuery.value)
const searchFocused = ref(false)
const filters = ref<InstanceType<typeof EwFilters> | null>(null)

watch(searchQuery, (value) => {
  // NOTE: do not update local search value if input have focus
  if (!searchFocused.value) {
    localSearchQuery.value = value
  }
})

watch(selectedCardType, () => {
  resetFilters()
})

watch(selectedSorting, () => {
  filters.value?.close()
})

const onSearchInput = debounce(function (value) {
  searchQuery.value = value
}, 350)

function onSearchBlur() {
  searchFocused.value = false
  searchQuery.value = localSearchQuery.value
}

function onSearchEnter() {
  searchQuery.value = localSearchQuery.value
}

const currentCardType = computed(() => selectedCardType.value ?? 'digital')

const searchPlaceholder = computed(() => {
  if (isPhysicalEnabled.value) {
    return selectedCardType.value === 'physical'
      ? 'Search physical gift cards'
      : 'Search digital gift cards'
  }

  return `Search ${searchGroup.value}`
})
</script>

<template>
  <div class="relative flex w-auto space-x-6 pt-8 sm:flex-nowrap">
    <template v-if="isPhysicalEnabled">
      <base-tabs
        v-if="ui.mobile"
        :value="currentCardType"
        @input="selectedCardType = $event"
      >
        <base-tab
          v-for="tab in cardTypes"
          :key="tab.value"
          :name="tab.label"
          :value="tab.value"
        />
      </base-tabs>
      <base-switch
        v-else
        class="w-full sm:w-48"
        :value="currentCardType"
        :values="cardTypes"
        @input="selectedCardType = $event"
      />
    </template>

    <div class="relative hidden grow sm:block">
      <base-input
        v-model="localSearchQuery"
        :placeholder="searchPlaceholder"
        icon-plain
        :look="!ui.mobile ? 'rounded' : ''"
        clearable
        nolabel
        autofocus
        @input="onSearchInput"
        @focus="searchFocused = true"
        @blur="onSearchBlur"
        @keypress.enter="onSearchEnter"
      >
        <template #icon>
          <base-icon
            svg="heroicons/outline/magnifying-glass"
            :size="24"
            class="ml-[15px] mr-2.5 text-eonx-neutral-600"
          />
        </template>
      </base-input>
    </div>

    <ew-categories
      :categories="categories"
      :selected-id="selectedCategory"
      :render-link="false"
      title="Categories"
      @change="selectedCategory = $event"
    />
    <ew-filters
      ref="filters"
      :is-filters-selected="isFiltersSelected"
      @clear="resetFilters"
    >
      <ew-sorting
        :value="selectedSorting"
        :sorting="sortValues"
        @input="selectedSorting = $event"
      />
    </ew-filters>
  </div>
</template>
